import React from 'react';
import { useContext } from 'react';
import LinkWithPageTransition from '../components/link-with-page-transition';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import LokaltogContext from '../context/context';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { IOperationalChangesOverviewPage } from '../types/heartcore.types';
import { dateFormatter, dateIsSet } from '../utils/utils';

const OperationalChangesOverviewPage = ({ pageContext }: PageData<IOperationalChangesOverviewPage>) => {
	const lokaltogContext = useContext(LokaltogContext);
	const { meta, name, modules, children: listOfOperationalChange, title = '' } = useContent<IOperationalChangesOverviewPage>(pageContext, lokaltogContext.rootNodeUrl);
	const list = listOfOperationalChange?.filter(o => !o.url.includes('/alt-om-rejsen/planlagte-aendringer/'));

	return (
		<>
			<Meta {...meta} />
			<div className="operational-changes box-wide">
				<h1 className="operational-changes__title">{title.length > 0 ? title : name}</h1>
				<ModulesController modules={modules} />
				{list?.length > 0 
					? <ul className="operational-changes__list">
						{list
							.map(oc => (
								<li className="operational-changes__item" key={oc.id}>
									<LinkWithPageTransition classes='link-with-underline' url={oc.url}>
										<h3 className="operational-changes__subject">
											<span className='link-with-underline'>{oc.overviewHeadline}</span>
										</h3>
										<p className="operational-changes__interval">
											{dateIsSet(oc.startDate) 
												? `${dateFormatter(oc.startDate)} ${dateIsSet(oc.endDate) 
													? ` - ${dateFormatter(oc.endDate)}` : ''}`
												: ''
											}
										</p>
										<p className="operational-changes__display-text">{oc.overviewDescription}</p>
									</LinkWithPageTransition>
								</li>
							))}
					</ul>
					: <p>{lokaltogContext.tr('OperationalChangesOverviewPage.NoPlannedOperationalChanges')}</p>
				}
			</div>
		</>
	);
};

export default OperationalChangesOverviewPage;
